import axios from 'axios';
import { apiWrapper } from '../../config/api-wrapper';
import { Attachment } from '../../interface/attachment';
import { WithAbortFn } from '../../@types/external-api';
import { AttachmentDTO } from '../../interface/attachment-dto';

const ATTACHMENTS_ENDPOINT = '/attachments';
class AttachmentService {
  public getAttachments(submissionId: number) {
    const source = axios.CancelToken.source();

    return {
      promise: apiWrapper.api(`${ATTACHMENTS_ENDPOINT}/${submissionId}`).then((res: any) => res.data),
      abort: source.cancel,
    };
  }

  public getEmailAttachments(submissionId: number) {
    const source = axios.CancelToken.source();

    return {
      promise: apiWrapper.api(`${ATTACHMENTS_ENDPOINT}/email-attachment/${submissionId}`).then((res: any) => res.data),
      abort: source.cancel,
    };
  }

  public signAttachment(attachment: Attachment) {
    const source = axios.CancelToken.source();

    return {
      promise: apiWrapper.api.post(`${ATTACHMENTS_ENDPOINT}/sign`, attachment).then((res: any) => res.data),
      abort: source.cancel,
    };
  }

  public signEmailAttachment(attachment: Attachment) {
    const source = axios.CancelToken.source();

    return {
      promise: apiWrapper.api
        .post(`${ATTACHMENTS_ENDPOINT}/email-attachment/sign`, attachment)
        .then((res: any) => res.data),
      abort: source.cancel,
    };
  }

  public updateStatusToUploaded(attachmentId: number): WithAbortFn<Promise<AttachmentDTO>> {
    const source = axios.CancelToken.source();

    return {
      promise: apiWrapper.api.patch(`${ATTACHMENTS_ENDPOINT}/${attachmentId}/uploaded`).then((res: any) => res.data),
      abort: source.cancel,
    };
  }

  public updateStatusToFailed(attachmentId: number): WithAbortFn<Promise<AttachmentDTO>> {
    const source = axios.CancelToken.source();

    return {
      promise: apiWrapper.api.patch(`${ATTACHMENTS_ENDPOINT}/${attachmentId}/failed`).then((res: any) => res.data),
      abort: source.cancel,
    };
  }

  public deleteAttachment(attachmentId: number): WithAbortFn<Promise<AttachmentDTO>> {
    const source = axios.CancelToken.source();

    return {
      promise: apiWrapper.api.delete(`${ATTACHMENTS_ENDPOINT}/${attachmentId}/delete`).then((res: any) => res.data),
      abort: source.cancel,
    };
  }
}

const attachmentService = new AttachmentService();
export default attachmentService;
