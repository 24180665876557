import { configureStore } from '@reduxjs/toolkit';
import submissionReducer from '../reducers/submissionReducer';
import transcriptReducer from '../reducers/transcriptReducer';
import ruleViolationModalReducer from '../reducers/ruleViolationModalReducer';
import dashboardReducer from '../reducers/dashboardReducer';
import reviewReducer from '../reducers/reviewReducer';
import submissionHistoryReducer from '../reducers/submissionHistoryReducer';
import summaryReducer from '../reducers/summaryReducer';
import notesReducer from '../reducers/notesReducer';

export const store = configureStore({
  reducer: {
    submission: submissionReducer,
    transcript: transcriptReducer,
    ruleViolationModal: ruleViolationModalReducer,
    dashboard: dashboardReducer,
    review: reviewReducer,
    submissionHistory: submissionHistoryReducer,
    summary: summaryReducer,
    notes: notesReducer,
  },
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
