import axios, { AxiosRequestConfig } from 'axios';
import { apiWrapper } from '../../config/api-wrapper';
import { WithAbortFn } from '../../@types/external-api';
import { NoteDTO } from '../../interface/note-dto';

const NOTES_ENDPOINT = '/notes';

class NotesService {
  public getNotes(submissionId: number): WithAbortFn<Promise<NoteDTO[] | undefined>> {
    const source = axios.CancelToken.source();

    return {
      promise: apiWrapper
        .api(`${NOTES_ENDPOINT}/${submissionId}`)
        .then((res: AxiosRequestConfig<NoteDTO[]> | undefined) => res?.data),
      abort: source.cancel,
    };
  }
  public updateNote(note: NoteDTO): WithAbortFn<Promise<NoteDTO | undefined>> {
    const source = axios.CancelToken.source();

    return {
      promise: apiWrapper.api
        .put(`${NOTES_ENDPOINT}/update`, note)
        .then((res: AxiosRequestConfig<NoteDTO> | undefined) => res?.data),
      abort: source.cancel,
    };
  }
  public createNote(note: NoteDTO): WithAbortFn<Promise<NoteDTO | undefined>> {
    const source = axios.CancelToken.source();

    return {
      promise: apiWrapper.api
        .post(`${NOTES_ENDPOINT}/create`, note)
        .then((res: AxiosRequestConfig<NoteDTO> | undefined) => res?.data),
      abort: source.cancel,
    };
  }
}

const notesService = new NotesService();
export default notesService;
