import './index.scss';
import { CiEdit } from 'react-icons/ci';
import { NoteDTO } from '../../../../interface/note-dto';
import { useAppSelector } from '../../../../redux/hooks';
import { GoDotFill } from 'react-icons/go';
import { formatDate } from '../../../../helpers/date-helper';

interface NoteCardProps {
  selectNote: (note: NoteDTO) => void;
  setIsEditing: (state: boolean) => void;
  note: NoteDTO;
}

export default function NoteCard({ note, selectNote, setIsEditing }: NoteCardProps) {
  const selectedNote: NoteDTO = useAppSelector(state => state.notes.selectedNote);

  function handleEditNote(e: React.MouseEvent, note: NoteDTO) {
    e.stopPropagation();
    selectNote(note);
    setIsEditing(true);
  }

  return (
    <div
      className={`notes-tab-list-card ${selectedNote?.id === note.id ? 'selected' : ''}`}
      onClick={() => selectNote(note)}>
      <div className="notes-tab-list-card-text">
        <span className="notes-tab-list-card-title">{note.title}</span>
        <span className="notes-tab-list-card-content">{note.content}</span>

        <div className="notes-tab-list-card-infos">
          <span>{formatDate(note.createdAt!, 'YYYY-MM-DD hh:mm a')}</span>
          <GoDotFill size={6} color="#5E677B" />
          <span>{note.user}</span>
        </div>
      </div>
      <div className="notes-tab-list-card-actions">
        <CiEdit className="edit-action" onClick={e => handleEditNote(e, note)} />
      </div>
    </div>
  );
}
