import { Paper } from '@mui/material';
import './index.scss';
import { Button } from 'react-bootstrap';
import { DropdownState, ViolationRule } from '../../../resources/violation-rule/violation-rule-types';
import React, { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import AlertComponent from '../../../components/ui/alert';
import { ReviewIssueViolationRule } from '../../../interface/review-issue-violation-rule';
import LoadingScreen from '../../loading';
import submissionService from '../../../resources/submission/submission.service';
import { SubmissionDetailsDTO } from '../../../interface/submission-details-dto';
import { ReviewIssue } from '../../../resources/submission/submission-types';
import { SubmissionIssueDTO } from '../../../interface/submission-issue-dto';
import * as submissionReducer from '../../../redux/reducers/submissionReducer';
import IssueInputs from './issue-inputs';
import IssuesListComponent from '../issue-list-component';
import * as transcriptReducer from '../../../redux/reducers/transcriptReducer';
import { getLastReview, hasTheReviewBeenSent } from '../../../helpers/reviews-helper';
import AlertMessages from '../../../resources/messages/alerts.json';
import { Review } from '../../../interface/review';

export interface Row {
  id: number | string;
}

const initialDropdownState: DropdownState = {
  id: undefined,
  showDropdown: false,
  showRightPanel: false,
  outmostSelectedParentViolationRule: '',
  selectedViolationRuleValue: '',
  selectedParentViolationRule: '',
  rightPanelViolationRules: [],
  description: '',
  violationRuleId: null,
};
const initialRows: Row[] = [{ id: 1 }];
const ADD_RULES_TITLE = 'Add Rules';
const EDIT_RULES_TITLE = 'Edit Rules';
const ISSUE_TITLE = 'Select an Issue';

export default function IssuesTab() {
  const [rows, setRows] = useState<Row[]>(initialRows); // Initial rows with one item
  const [dropdownStates, setDropdownStates] = useState<DropdownState[]>(rows.map(() => ({ ...initialDropdownState })));
  const [rowsToUpdate, setRowsToUpdate] = useState<Row[]>([]);
  const [dropdownStatesToUpdate, setDropdownStatesToUpdate] = useState<DropdownState[]>([]);
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [creationMode, setCreationMode] = useState<boolean>(false);
  const [editMode, setEditMode] = useState<boolean>(false);
  const [isCreating, setIsCreating] = useState<boolean>(false);
  const [isUpdating, setIsUpdating] = useState<boolean>(false);
  const [selectedIssue, setSelectedIssue] = useState<SubmissionIssueDTO | null>(null);
  const [rightPanelTitle, setRightPanelTitle] = useState<string>(ISSUE_TITLE);
  const reviews: Review[] = useAppSelector(state => state.submission.reviews);
  const lastReview = getLastReview(reviews);
  const { violationRules } = useAppSelector(state => state.transcript);
  const currentRecord: SubmissionDetailsDTO = useAppSelector(state => state.submission.currentRecord);
  const {
    submissionReviews,
    submissionDetails,
    currentSubmissionIndex,
    ruleViolationsInfoMessage,
    enableReview,
    blockedInteraction,
  } = useAppSelector(state => state.submission);
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (creationMode) {
      setRightPanelTitle(ADD_RULES_TITLE);
    } else if (selectedIssue) {
      setRightPanelTitle(EDIT_RULES_TITLE);
    } else {
      setRightPanelTitle(ISSUE_TITLE);
    }
  }, [selectedIssue, creationMode, editMode]);

  function saveRules(e: React.MouseEvent<HTMLButtonElement>) {
    e.preventDefault();
    setErrorMessage('');

    const reviewIssueViolationRule: ReviewIssueViolationRule[] = dropdownStates.map(
      (item: DropdownState) =>
        ({
          id: item.id,
          description: item.description,
          violationRuleId: item.violationRuleId,
          userReviewStatus: true,
        } as ReviewIssueViolationRule),
    );

    if (reviewIssueViolationRule.length === 1 && !reviewIssueViolationRule[0].violationRuleId) {
      setErrorMessage('You must to add at least one rule and select the rule violation for it');
      return;
    }

    if (!reviewIssueViolationRule.every(item => item.violationRuleId)) {
      setErrorMessage('All rules must have rule violation selected');
      return;
    }

    if (isCreating) {
      return;
    }

    setIsCreating(true);

    submissionService
      .createSubmissionIssue({} as ReviewIssue, currentRecord.submissionId.toString(), reviewIssueViolationRule)
      .promise.then((submissionIssue: SubmissionIssueDTO) => {
        updateNewSubmissionReview(submissionIssue);
      })
      .finally(() => {
        setIsCreating(false);
        setCreationMode(false);
        clearRows();
      });
  }

  function updateRules(e: React.MouseEvent<HTMLButtonElement>) {
    e.preventDefault();
    setErrorMessage('');

    const reviewIssueViolationRule: ReviewIssueViolationRule[] = dropdownStatesToUpdate.map(
      (item: DropdownState) =>
        ({
          id: item.id,
          description: item.description,
          violationRuleId: item.violationRuleId,
          userReviewStatus: true,
          reviewIssueId: selectedIssue?.reviewIssue.id,
        } as ReviewIssueViolationRule),
    );

    if (reviewIssueViolationRule.length === 1 && !reviewIssueViolationRule[0].violationRuleId) {
      setErrorMessage('You must to add at least one rule and select the rule violation for it');
      return;
    }

    if (!reviewIssueViolationRule.every(item => item.violationRuleId)) {
      setErrorMessage('All rules must have rule violation selected');
      return;
    }

    if (isUpdating) {
      return;
    }

    setIsUpdating(true);

    submissionService
      .updateSubmissionIssue(
        selectedIssue!.reviewIssue,
        currentRecord.submissionId.toString(),
        reviewIssueViolationRule,
      )
      .promise.then((submissionIssue: SubmissionIssueDTO) => {
        updateSubmissionReview(submissionIssue);
      })
      .finally(() => {
        setIsUpdating(false);
      });
  }

  /**
   * Update an edited violation rule.
   * @param reviewIssue
   */
  function updateSubmissionReview(submissionIssue: SubmissionIssueDTO) {
    const updatedSubmissionReviews: SubmissionIssueDTO[] = [...submissionReviews];
    const submissionReviewIndexToBeUpdated = updatedSubmissionReviews.findIndex(
      sr => sr.reviewIssue.id === submissionIssue.reviewIssue.id,
    );

    if (submissionReviewIndexToBeUpdated !== -1) {
      updatedSubmissionReviews[submissionReviewIndexToBeUpdated] = { ...submissionIssue };
      dispatch(submissionReducer.setSubmissionReviews(updatedSubmissionReviews));
    }
  }

  /**
   * Insert the new issue created by using the transcript selection into the review tab.
   */
  function updateNewSubmissionReview(submissionIssue: SubmissionIssueDTO) {
    const updatedSubmissionReviews = submissionReviews.concat([submissionIssue]);
    const updatedSubmissionsReviews = [...submissionDetails.submissionsReviews];
    updatedSubmissionsReviews[currentSubmissionIndex] = updatedSubmissionReviews;

    dispatch(submissionReducer.setSubmissionReviews(updatedSubmissionReviews));
    dispatch(
      submissionReducer.setSubmissionDetails({ ...submissionDetails, submissionsReviews: updatedSubmissionsReviews }),
    );
  }

  function clearRows() {
    setDropdownStates([initialDropdownState]);
    setRows(initialRows);
  }

  function cancelCreation() {
    setCreationMode(false);
    clearRows();
  }

  function cancelEdit() {
    setEditMode(false);
    setSelectedIssue(null);
    dispatch(transcriptReducer.setSelectedIssueOccurrence({}));
  }

  function addRuleViolation() {
    if (hasTheReviewBeenSent(lastReview) && !enableReview) {
      handleInfoMessage(AlertMessages.THE_REVIEW_HAS_ALREADY_BEEN_SENT);
      return;
    }

    setCreationMode(true);
  }

  function handleEditIssue(submissionReview: SubmissionIssueDTO) {
    selectIssue(submissionReview);
    setEditMode(true);
  }

  function clearDropdownStates(dropdownStates: Array<DropdownState>): Array<DropdownState> {
    return dropdownStates.filter((dropdownState: DropdownState) => dropdownState.id !== undefined);
  }

  function handleInfoMessage(message: string) {
    dispatch(submissionReducer.setRuleViolationsInfoMessage(message));
  }

  function selectIssue(submissionReview: SubmissionIssueDTO) {
    if (hasTheReviewBeenSent(lastReview) && !enableReview) {
      handleInfoMessage(AlertMessages.THE_REVIEW_HAS_ALREADY_BEEN_SENT);
      return;
    }

    setEditMode(true);
    setCreationMode(false);
    setSelectedIssue(submissionReview);
    dispatch(transcriptReducer.setSelectedIssueOccurrence(submissionReview));

    if (submissionReview && submissionReview.reviewIssueViolationRule?.length > 0) {
      const rows: Row[] = [];

      submissionReview?.reviewIssueViolationRule.forEach((rivr: ReviewIssueViolationRule, index: number) => {
        rows.push({ id: index + 1 });
      });

      let initialDropdownStates: Array<DropdownState> = submissionReview?.reviewIssueViolationRule.map(
        (reviewIssueViolationRule: ReviewIssueViolationRule) => {
          const violationRule = violationRules.find(
            (vr: ViolationRule) => vr.id === reviewIssueViolationRule.violationRuleId,
          ) as ViolationRule;
          const parentViolationRule = violationRules.find(
            (vr: ViolationRule) => vr.id === violationRule.parentId,
          ) as ViolationRule;
          const outmostViolationRule = violationRules.find(
            (vr: ViolationRule) => vr.id === parentViolationRule.parentId,
          ) as ViolationRule;

          return {
            id: reviewIssueViolationRule.id,
            showDropdown: false,
            showRightPanel: false,
            outmostSelectedParentViolationRule: outmostViolationRule?.name || '',
            selectedViolationRuleValue:
              (outmostViolationRule ? outmostViolationRule.name + ' > ' : '') +
              (parentViolationRule ? parentViolationRule.name + ' > ' : '') +
              (violationRule ? violationRule.name : 'Select a new Rule Violation'),
            selectedParentViolationRule: '',
            rightPanelViolationRules: [],
            description: reviewIssueViolationRule?.description || '',
            violationRuleId: reviewIssueViolationRule.violationRuleId,
          } as DropdownState;
        },
      );

      initialDropdownStates = clearDropdownStates(initialDropdownStates);

      setRowsToUpdate(rows);
      setDropdownStatesToUpdate(initialDropdownStates);
    } else {
      setRows(initialRows);
      setDropdownStates([initialDropdownState]);
    }
  }

  return (
    <div className="issues-tab-container">
      {errorMessage && (
        <AlertComponent text={errorMessage} type="danger" buttonText="X" onClick={() => setErrorMessage('')} />
      )}
      {ruleViolationsInfoMessage && (
        <AlertComponent
          type="info"
          text={ruleViolationsInfoMessage}
          buttonText="X"
          onClick={() => dispatch(submissionReducer.setRuleViolationsInfoMessage(''))}
        />
      )}
      <div className="issues-tab-papers">
        <div className="issues-tab-list-container">
          <IssuesListComponent
            tab="issues"
            issueClick={selectIssue}
            editIssue={handleEditIssue}
            addRuleViolation={addRuleViolation}
          />
        </div>

        <Paper className="issues-tab-form-container" variant="outlined">
          <div className="paper-title">
            <span className="paperText">{rightPanelTitle}</span>
          </div>

          <div className="issues-tab-form-content">
            {creationMode ? (
              <>
                <div hidden={!isCreating} style={{ width: '100%', height: '100%' }}>
                  <LoadingScreen styles={{ width: '100%', height: '100%' }} />
                </div>
                <div hidden={isCreating}>
                  <IssueInputs
                    rows={rows}
                    dropdownStates={dropdownStates}
                    setRows={setRows}
                    setDropdownStates={setDropdownStates}
                  />
                  <div className="issues-tab-form-content-buttons">
                    <Button className="inputs-cancel-button" onClick={cancelCreation}>
                      Cancel
                    </Button>
                    <Button className="inputs-submit-button" onClick={e => saveRules(e)}>
                      Save
                    </Button>
                  </div>
                </div>
              </>
            ) : editMode ? (
              <>
                <div hidden={!isUpdating} style={{ width: '100%', height: '100%' }}>
                  <LoadingScreen styles={{ width: '100%', height: '100%' }} />
                </div>
                <div hidden={isUpdating}>
                  <IssueInputs
                    rows={rowsToUpdate || []}
                    dropdownStates={dropdownStatesToUpdate || []}
                    setRows={setRowsToUpdate}
                    setDropdownStates={setDropdownStatesToUpdate}
                  />
                  <div className="issues-tab-form-content-buttons">
                    <Button className="inputs-cancel-button" onClick={cancelEdit}>
                      Cancel
                    </Button>
                    <Button className="inputs-submit-button" onClick={e => updateRules(e)}>
                      Save
                    </Button>
                  </div>
                </div>
              </>
            ) : (
              <></>
            )}
          </div>
        </Paper>
      </div>
    </div>
  );
}
