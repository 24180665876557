import './index.scss';
import React from 'react';
import { CiCircleMinus, CiCirclePlus } from 'react-icons/ci';
import { Dropdown } from 'react-bootstrap';
import { DropdownState, ViolationRule } from '../../../../resources/violation-rule/violation-rule-types';
import { useAppSelector } from '../../../../redux/hooks';
import { v4 as uuidv4 } from 'uuid';
import { Row } from '..';

interface IssueInputsProps {
  rows: Row[];
  dropdownStates: DropdownState[];
  setRows: React.Dispatch<React.SetStateAction<Row[]>>;
  setDropdownStates: React.Dispatch<React.SetStateAction<DropdownState[]>>;
}

export default function IssueInputs({ rows, dropdownStates, setRows, setDropdownStates }: IssueInputsProps) {
  const { nestedViolationRules } = useAppSelector(state => state.transcript);

  /**
   * Handles adding a new rule violation to the same text section
   */
  const addRow = () => {
    const newRow = { id: uuidv4() };

    setRows(prevState => [...prevState, newRow]);
    setDropdownStates(prevState => [
      ...prevState,
      {
        id: undefined,
        showDropdown: false,
        showRightPanel: false,
        outmostSelectedParentViolationRule: '',
        selectedViolationRuleValue: '',
        selectedParentViolationRule: '',
        rightPanelViolationRules: [],
        description: '',
        violationRuleId: null,
      },
    ]);
  };

  /**
   * Handle removal of a rule violation
   */
  const removeRow = (indexToRemove: number) => {
    setRows(prevState => prevState.filter((row, index) => index !== indexToRemove));
    setDropdownStates(prevState => prevState.filter((state, index) => index !== indexToRemove));
  };

  /**
   * Track whether the toggle is open or not to show the right-panel
   */
  const handleToggle = (index: number, isOpen: boolean) => {
    setDropdownStates(prevState =>
      prevState.map((state, i) => (i === index ? { ...state, showDropdown: isOpen, showRightPanel: isOpen } : state)),
    );
  };

  /**
   * When a violation rule is clicked, check if it has any children, if true then we should show them on the right-panel
   * @param rule
   * @param index
   * @param parentRuleId Store the parentRuleId when clicking on an item on the left panel
   */
  const violationRuleClick = (rule: ViolationRule, index: number, parentRuleName?: string) => {
    setDropdownStates(prevState =>
      prevState.map((state, i) => {
        if (i === index) {
          // If the current rule has children we should show them on the right panel.
          if (rule.children?.length) {
            return {
              ...state,
              outmostSelectedParentViolationRule: parentRuleName!,
              selectedParentViolationRule: rule.name,
              rightPanelViolationRules: rule.children,
            };
          } else {
            // Click on a rule that does not have any children, select it.
            let selectedViolationRuleValue = '';
            if (parentRuleName) {
              selectedViolationRuleValue = parentRuleName + ' > ' + rule.name;
            } else {
              selectedViolationRuleValue =
                state.outmostSelectedParentViolationRule +
                ' > ' +
                state.selectedParentViolationRule +
                ' > ' +
                rule.name;
            }
            return {
              ...state,
              showDropdown: false,
              showRightPanel: false,
              selectedViolationRuleValue: selectedViolationRuleValue,
              violationRuleId: rule.id,
            };
          }
        }
        return state;
      }),
    );
  };

  /**
   * Handles input change from the textarea to persist it to it's parent component
   * @param index
   * @param value
   */
  const handleDescriptionChange = (index: number, value: string) => {
    setDropdownStates(prevState =>
      prevState.map((state, i) => (i === index ? { ...state, description: value } : state)),
    );
  };

  return (
    <div className="ruleViolationContainer">
      {rows.map((row, index) => (
        <div className="ruleViolationRow" key={row.id}>
          <div className="selectionSection">
            <Dropdown
              show={dropdownStates[index].showDropdown}
              onToggle={isOpen => handleToggle(index, isOpen)}
              autoClose={false}>
              <Dropdown.Toggle id="dropdown-basic">
                {dropdownStates[index].selectedViolationRuleValue || 'Select a new Rule Violation'}
              </Dropdown.Toggle>
              <Dropdown.Menu
                style={{
                  background: dropdownStates[index].rightPanelViolationRules.length ? '#F2F2F2' : 'white',
                }}>
                {nestedViolationRules?.map((parentRule: ViolationRule) => (
                  <React.Fragment key={parentRule.id}>
                    <Dropdown.Header>{parentRule.name}</Dropdown.Header>
                    {parentRule.children?.map(rule => {
                      if (
                        Array.isArray(dropdownStates) &&
                        !dropdownStates.some(violationRule => violationRule.violationRuleId === rule.id)
                      ) {
                        return (
                          <Dropdown.Item key={rule.id} onClick={() => violationRuleClick(rule, index, parentRule.name)}>
                            {rule.name}
                          </Dropdown.Item>
                        );
                      }
                      return null;
                    })}
                  </React.Fragment>
                ))}
              </Dropdown.Menu>
              {dropdownStates[index].showRightPanel && (
                <div
                  className="right-panel"
                  style={{
                    background: dropdownStates[index].rightPanelViolationRules.length ? 'white' : '#F2F2F2',
                  }}>
                  {dropdownStates[index].rightPanelViolationRules.map((childRule: ViolationRule) => {
                    if (!dropdownStates?.some(violationRule => violationRule.violationRuleId === childRule.id)) {
                      return (
                        <Dropdown.Item key={childRule.id} onClick={() => violationRuleClick(childRule, index)}>
                          {childRule.name}
                        </Dropdown.Item>
                      );
                    }
                    return null;
                  })}
                </div>
              )}
            </Dropdown>
            <textarea
              className="ruleViolationDescription"
              id={`formControlTextarea_${index}`} // Unique ID for each textarea
              rows={4}
              value={dropdownStates[index].description.replaceAll('double_quotes', '"')}
              disabled={dropdownStates[index].selectedViolationRuleValue ? false : true}
              placeholder="Select a rule in order to add the description..."
              onChange={e => handleDescriptionChange(index, e.target.value)}
            />
          </div>
          <div className="controlSection">
            {index === rows.length - 1 ? (
              <div className="last-item">
                {index != 0 ? <CiCircleMinus onClick={() => removeRow(index)} className="remove" /> : null}
                <CiCirclePlus onClick={addRow} className="add" />
              </div>
            ) : (
              <CiCircleMinus onClick={() => removeRow(index)} className="remove" />
            )}
          </div>
        </div>
      ))}
    </div>
  );
}
