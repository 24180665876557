/* eslint-disable react-hooks/exhaustive-deps */
//TODOS:
// - Add blue background to selected submission
// - Make table head fixed to its position when scrolled
// - change cursor when on top of a submission

import { useState, useEffect, useCallback } from 'react';
import { Card, Col, Container, Row } from 'react-bootstrap';
import { Typography, IconButton, LogoLoading } from '@amway/react-components';
import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import { BsChevronDown, BsChevronUp, BsCaretDownFill, BsCaretUpFill } from 'react-icons/bs';

import { IOption } from '../../components/ui/multi-select-dropdown';
import { getFiltersFromStorage } from '../../helpers/filters-local-storage';
import { formatSourceDate, tableCols } from './utils';
import { capitalizeFirstLetter, capitalizeWords } from '../../utils/string-utils';
import DashboardFilters from './dashboard-filters/dashboard-filters';
import useAuth from '../../resources/auth/auth-hook';
import submissionService from '../../resources/submission/submission.service';
import { SortOrder, SubmissionDashboardDTO } from '../../interface/submission-dashboard-dto';
import { useAppSelector } from '../../redux/hooks';

import './dashboard.scss';

interface DashboardRow {
  id: number;
  recordId: string;
  date: any;
  summary: string;
  selected: boolean;
  englishTitle: string;
  assignedTo: string;
  submitterName: string;
  sku: string;
  speaker: string;
  status: string;
  applyCode: number;
  mediaType: string;
  open: boolean;
  modelDecision: string;
  approvedProviderName: string;
}

export default function DashboardComponent() {
  const columnTitleStyle = { fontWeight: 'bold' };
  const defaultFilters = getFiltersFromStorage();

  const [summary] = useState();
  const [sortCol, setSortCol] = useState<string>('created_at');
  const [sortOrder, setSortOrder] = useState<SortOrder>('DESC');
  const [selectedAffiliate, setSelectedAffiliate] = useState<string[]>(defaultFilters.affiliate || []);
  const [selectedStatus, setSelectedStatus] = useState<string[]>(defaultFilters.status || []);
  const [selectedUser, setSelectedUser] = useState<string[]>(defaultFilters.selectedUser || []);
  const [selectedSubmitter, setSelectedSubmitter] = useState<string[]>(defaultFilters.selectedSubmitter || []);
  const [selectedApprovedProvider, setSelectedApprovedProvider] = useState<string[]>(
    defaultFilters.approvedProvider || [],
  );
  const [rowSearch, setRowSearch] = useState(defaultFilters.search);
  const [openedRows, setOpenedRows] = useState<string[]>([]);
  const [submissions, setSubmissions] = useState<Array<SubmissionDashboardDTO>>([]);
  const [isRequesting, setIsRequesting] = useState<Boolean>(true);
  const [isAnyAutocompleteOpen, setIsAnyAutocompleteOpen] = useState<boolean>(false);

  useEffect(() => {
    if (!isAnyAutocompleteOpen) {
      const searchSubmissionsTimeout = setTimeout(() => {
        fetchSearchSubmissions();
      }, 500);

      return () => clearTimeout(searchSubmissionsTimeout);
    }
  }, [rowSearch, sortCol, sortOrder, isAnyAutocompleteOpen]);

  const handleOpenStateChange = useCallback((open: boolean) => {
    setIsAnyAutocompleteOpen(open);
  }, []);

  function fetchSearchSubmissions() {
    setIsRequesting(true);

    submissionService
      .searchSubmissions({
        status: selectedStatus,
        affiliateId: selectedAffiliate,
        search: rowSearch,
        sortCol: sortCol,
        sortOrder: sortOrder,
        assignedToName: selectedUser,
        submitterName: selectedSubmitter,
        approvedProviderName: selectedApprovedProvider,
      })
      .promise.then((submissions: Array<SubmissionDashboardDTO>) => {
        setSubmissions(submissions);
      })
      .catch((error: Error) => {
        console.error(error.message);
      })
      .finally(() => {
        setIsRequesting(false);
      });
  }

  function dashboardRows(submissions: Array<SubmissionDashboardDTO>): DashboardRow[] {
    return submissions.map(submission => ({
      id: submission.id,
      recordId: submission.recordId,
      date: formatSourceDate(submission.createdAt),
      status: submission.workflowStatus !== null ? submission.workflowStatus : '-',
      summary:
        submission.transcriptSummary !== null ? submission.transcriptSummary : "This submission doesn't have a summary",
      selected: false,
      mediaType: submission.mediaType !== null ? capitalizeWords(submission.mediaType.toLowerCase()) : '-',
      submitterName: submission.submitterName || submission.submitterOktaId || '',
      sku: submission.sku !== null ? submission.sku : '-',
      speaker: submission.speaker !== null ? submission.speaker : '-',
      assignedTo: submission.assignedToName !== null ? submission.assignedToName : 'UnAssigned',
      englishTitle: submission.englishTitle,
      applyCode: submission.applyCode,
      open: openedRows.includes(submission.recordId),
      modelDecision:
        submission.modelDecision !== null ? capitalizeFirstLetter(submission.modelDecision.toLocaleLowerCase()) : '-',
      approvedProviderName: submission.approvedProviderName !== null ? submission.approvedProviderName : '-'
    }));
  }

  function handleBtnClick(submission: DashboardRow) {
    if (submission.open === true) {
      setOpenedRows(openedRows.filter(recordId => recordId !== submission.recordId));
    } else {
      setOpenedRows([...openedRows, submission.recordId]);
    }
  }

  function sortBy(col: string) {
    if (sortCol === col) {
      setSortOrder(sortOrder === 'DESC' ? 'ASC' : 'DESC');
    } else {
      setSortCol(col);
      setSortOrder('DESC');
    }
  }

  return (
    <Container className="approval">
      <Card>
        <Card.Body>
          <div className="color-scheme--acdp-na">
            <Row>
              <Col>
                <div className="title">
                  <Typography variant="heading">Compliance Companion</Typography>
                </div>
                <br />
              </Col>
            </Row>
            <Row>
              <DashboardFilters
                rowSearch={rowSearch}
                setRowSearch={setRowSearch}
                selectedAffiliate={selectedAffiliate}
                selectedStatus={selectedStatus}
                selectedUser={selectedUser}
                selectedApprovedProvider={selectedApprovedProvider}
                selectedSubmitter={selectedSubmitter}
                setSelectedAffiliate={setSelectedAffiliate}
                setSelectedStatus={setSelectedStatus}
                setSelectedUser={setSelectedUser}
                setSelectedSubmitter={setSelectedSubmitter}
                setSelectedApprovedProvider={setSelectedApprovedProvider}
                onOpenStateChange={handleOpenStateChange}
              />
            </Row>
            <div className="flex">
              <div className={`table ${isRequesting ? 'min-height' : ''}`}>
                <TableContainer component={Paper}>
                  <Table aria-label="a dense table">
                    <TableHead>
                      <TableRow>
                        <>
                          {tableCols.map((col: any) => (
                            <TableCell
                              key={'col' + col.sort}
                              id={'col' + col.sort}
                              style={columnTitleStyle}
                              width={col.width || null}>
                              <span
                                onClick={() => {
                                  sortBy(col.sort.toString());
                                }}>
                                {col.label}
                                {sortCol === col.sort && sortOrder === 'DESC' && <BsCaretDownFill />}
                                {sortCol === col.sort && sortOrder === 'ASC' && <BsCaretUpFill />}
                              </span>
                            </TableCell>
                          ))}
                          <TableCell width={20} align="right" style={columnTitleStyle}>
                            &nbsp;
                          </TableCell>
                        </>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {isRequesting ? (
                        <div className="loader-container">
                          <LogoLoading />
                        </div>
                      ) : (
                        <>
                          {dashboardRows(submissions).map((submission: DashboardRow) => (
                            <>
                              <TableRow
                                id={submission.recordId}
                                key={submission.recordId}
                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                className={`tableRow ${submission.selected === true ? 'selectedRow' : null}`}>
                                <TableCell>
                                  <a href={'/submission?applyCode=' + submission.applyCode}>{submission.applyCode}</a>
                                </TableCell>
                                <TableCell>{submission.date}</TableCell>
                                <TableCell>{submission.assignedTo}</TableCell>
                                <TableCell>{submission.mediaType}</TableCell>
                                <TableCell>{submission.englishTitle}</TableCell>
                                <TableCell>{submission.approvedProviderName}</TableCell>
                                <TableCell>{submission.sku}</TableCell>
                                <TableCell>{submission.speaker}</TableCell>
                                <TableCell>{submission.status}</TableCell>
                                <TableCell>{submission.modelDecision}</TableCell>
                                <TableCell>
                                  <IconButton
                                    IconComponent={submission.open ? BsChevronUp : BsChevronDown}
                                    color="#000"
                                    size="14px"
                                    className="table-icon"
                                    onClick={() => handleBtnClick(submission)}
                                  />
                                </TableCell>
                              </TableRow>
                              <TableRow
                                id={'sub-' + submission.id}
                                key={'sub-' + submission.id}
                                className={`tableSubRow ${submission.open === true ? 'open' : ''}`}>
                                <TableCell colSpan={11}>{submission.summary}</TableCell>
                              </TableRow>
                            </>
                          ))}
                        </>
                      )}
                    </TableBody>
                  </Table>
                </TableContainer>
              </div>
              {summary}
            </div>
          </div>
        </Card.Body>
      </Card>
    </Container>
  );
}
