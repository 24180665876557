import { PayloadAction, Slice, createSlice } from '@reduxjs/toolkit';
import { SubmissionHistoryDTO } from '../../interface/submission-history-dto';

interface SubmissionHistoryState {
  history: SubmissionHistoryDTO[];
}

const initialState: SubmissionHistoryState = {
  history: [],
};

const submissionHistorySlice: Slice = createSlice({
  name: 'submissionHistory',
  initialState: initialState,
  reducers: {
    setHistory: (state: SubmissionHistoryState, action: PayloadAction<SubmissionHistoryDTO[]>) => {
      state.history = action.payload;
    },
  },
});

export const { setHistory } = submissionHistorySlice.actions;

export default submissionHistorySlice.reducer;
