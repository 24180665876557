import mime from 'mime';
import { SubmissionDashboardDTO } from '../interface/submission-dashboard-dto';
import { SubmissionDetailsDTO } from '../interface/submission-details-dto';
import { SubmissionStatus } from '../enum/submission-status';

const processingStatuses = ['PROCESSING', 'PENDING'];
const bsmSupportedMediaTypes = ['audio', 'video'];

export function isSubmissionProcessing(submission: SubmissionDetailsDTO | SubmissionDashboardDTO): boolean {
  if (!submission) {
    return false;
  }

  return processingStatuses.includes(submission.submissionStatus);
}

export function hasSubmissionError(submission: SubmissionDetailsDTO): boolean {
  if (!submission) {
    return false;
  }

  return submission.submissionStatus === SubmissionStatus.ERROR;
}

export function isAudioOrVideoMediaType(gcsPath: string): boolean {
  if (!gcsPath) {
    return false;
  }

  const fileName = gcsPath.substring(gcsPath.lastIndexOf('/') + 1);
  let mediaType = getMimeType(fileName);

  return bsmSupportedMediaTypes.some((bsmSupportedMediaType: string) => mediaType.includes(bsmSupportedMediaType));
}

export function getMimeType(fileName: string): string {
  const mimeType = mime.getType(fileName);

  return mimeType || 'application/octet-stream';
}
