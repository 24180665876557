import './index.scss';
import { Paper } from '@mui/material';
import { useEffect, useState } from 'react';
import notesService from '../../../resources/notes/notes.service';
import { SubmissionDetailsDTO } from '../../../interface/submission-details-dto';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import * as notesReducer from '../../../redux/reducers/notesReducer';
import { NoteDTO } from '../../../interface/note-dto';
import { Button } from 'react-bootstrap';
import AlertComponent from '../../../components/ui/alert';
import LoadingScreen from '../../loading';
import useAuth from '../../../resources/auth/auth-hook';
import NoteCard from './note-card';
import { FormState } from '../../../interface/form-state';
import { FaPlus } from 'react-icons/fa6';

const defaultFormState = {
  title: '',
  content: '',
};

const DEFAULT_RIGHT_PAPER_TITLE = 'Select a note';

export default function NotesTab() {
  const selectedNote: NoteDTO = useAppSelector(state => state.notes.selectedNote);
  const currentRecord: SubmissionDetailsDTO = useAppSelector(state => state.submission.currentRecord);
  const notes: NoteDTO[] = useAppSelector(state => state.notes.notes);
  const dispatch = useAppDispatch();
  const { user } = useAuth();

  const [isEditing, setIsEditing] = useState<boolean>(false);
  const [isCreating, setIsCreating] = useState<boolean>(false);
  const [noteUpdateData, setNoteUpdateData] = useState<FormState>(defaultFormState);
  const [noteCreationData, setNoteCreationData] = useState<FormState>(defaultFormState);
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [isRequestingNotes, setRequestingNotes] = useState<boolean>(false);
  const [isCreatingOrUpdatingNote, setCreatingOrUpdatingNote] = useState<boolean>(false);
  const [rightPaperTitle, setRightPaperTitle] = useState<string>(selectedNote?.title || DEFAULT_RIGHT_PAPER_TITLE);

  useEffect(() => {
    fetchNotes();
  }, []);

  useEffect(() => {
    if (selectedNote) {
      setRightPaperTitle(selectedNote.title || 'Untitled');
    } else {
      setRightPaperTitle(DEFAULT_RIGHT_PAPER_TITLE);
    }
  }, [selectedNote]);

  function fetchNotes() {
    setRequestingNotes(true);
    notesService
      .getNotes(currentRecord.submissionId)
      .promise.then((notes: NoteDTO[] | undefined) => {
        dispatch(notesReducer.setNotes(notes));
      })
      .finally(() => {
        setRequestingNotes(false);
      });
  }

  function selectNote(note: NoteDTO) {
    setIsEditing(false);
    setIsCreating(false);
    dispatch(notesReducer.setSelectedNote(note));

    setNoteUpdateData(prevState => ({
      ...prevState,
      title: note.title || '',
      content: note.content,
    }));
  }

  function updateNote(note: NoteDTO) {
    const title = noteUpdateData.title;
    const content = noteUpdateData.content;

    if ((title.length > 0 && title.length < 3) || content.length < 3) {
      setErrorMessage('Please make sure both the title and content are at least 3 characters long');
      return;
    }

    const updatedNote = { ...note, title, content };

    setCreatingOrUpdatingNote(true);

    notesService
      .updateNote(updatedNote)
      .promise.then((noteRes: NoteDTO | undefined) => {
        dispatch(notesReducer.updateNote(noteRes));
        selectNote(updatedNote);
      })
      .finally(() => {
        setCreatingOrUpdatingNote(false);
      });
  }

  function createNote() {
    setRightPaperTitle('New note');
    setIsCreating(true);
    setIsEditing(false);
  }

  function cancelCreation() {
    setIsCreating(false);
    setNoteCreationData(defaultFormState);
    if (selectedNote) {
      setRightPaperTitle(selectedNote.title || 'Untitled');
    } else {
      setRightPaperTitle(DEFAULT_RIGHT_PAPER_TITLE);
    }
  }

  function submitNewNote() {
    setErrorMessage('');

    const title = noteCreationData.title;
    const content = noteCreationData.content;

    if (!content) {
      setErrorMessage('Please provide the content for your note. It cannot be empty.');
      return;
    }

    if ((title.length > 0 && title.length < 3) || content.length < 3) {
      setErrorMessage('Please make sure both the title and content are at least 3 characters long.');
      return;
    }

    const newNote: NoteDTO = {
      submissionId: currentRecord.submissionId,
      title: title.length === 0 ? null : title,
      content: content,
      createdBy: user?.id,
    };

    setCreatingOrUpdatingNote(true);

    notesService
      .createNote(newNote)
      .promise.then((noteRes: NoteDTO | undefined) => {
        if (noteRes) {
          dispatch(notesReducer.setNotes([noteRes, ...notes]));
          selectNote(noteRes);
        }
        setNoteCreationData(defaultFormState);
        setIsCreating(false);
      })
      .catch(error => {
        setErrorMessage('Failed to create the note. Please try again.');
        console.error('Error creating note:', error);
      })
      .finally(() => {
        setCreatingOrUpdatingNote(false);
      });
  }

  const handleNoteUpdateChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { name, value } = e.target;

    setNoteUpdateData(prevState => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleNoteCreationChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { name, value } = e.target;

    setNoteCreationData(prevState => ({
      ...prevState,
      [name]: value,
    }));
  };

  return isRequestingNotes ? (
    <LoadingScreen styles={{ width: '100%', height: '60vh' }} />
  ) : (
    <div className="notes-tab-container">
      {errorMessage && (
        <AlertComponent
          text={errorMessage}
          type="danger"
          buttonText="X"
          onClick={() => setErrorMessage('')}></AlertComponent>
      )}
      <div className="notes-tab-papers">
        <Paper className="notes-tab-list-container" variant="outlined">
          <div className="paper-title">
            <span className="paperText">Notes</span>
            <div className="new-note-container" onClick={createNote}>
              <FaPlus size={12} color="#2cda9b" />
              <span className="new-note-text">New note</span>
            </div>
          </div>
          <div className="notes-tab-list-content">
            <div className="notes-tab-list-cards">
              {notes.length > 0 ? (
                notes.map((note: NoteDTO, index: number) => (
                  <NoteCard note={note} key={index} selectNote={selectNote} setIsEditing={setIsEditing} />
                ))
              ) : (
                <p className="notes-tab-list-empty">There are no notes to show</p>
              )}
            </div>
          </div>
        </Paper>
        <Paper className="notes-tab-note-container" variant="outlined">
          <div className="paper-title">
            <span className="paperText">{rightPaperTitle}</span>
          </div>
          <div className="notes-tab-note-content">
            {isCreating ? (
              isCreatingOrUpdatingNote ? (
                <LoadingScreen styles={{ width: '100%', height: '100%' }} />
              ) : (
                <div className="notes-tab-note-content-inputs">
                  <input
                    id="title-creation"
                    name="title"
                    type="text"
                    placeholder="Title"
                    value={noteCreationData.title}
                    onChange={handleNoteCreationChange}
                  />
                  <textarea
                    id="content-creation"
                    name="content"
                    placeholder="Note"
                    onChange={handleNoteCreationChange}
                    value={noteCreationData.content}></textarea>
                  <div className="notes-tab-note-content-inputs-buttons">
                    <Button className="inputs-cancel-button" onClick={cancelCreation}>
                      Cancel
                    </Button>
                    <Button className="inputs-submit-button" onClick={submitNewNote}>
                      Save
                    </Button>
                  </div>
                </div>
              )
            ) : isEditing ? (
              isCreatingOrUpdatingNote ? (
                <LoadingScreen styles={{ width: '100%', height: '100%' }} />
              ) : (
                <div className="notes-tab-note-content-inputs">
                  <input
                    id="title-update"
                    name="title"
                    type="text"
                    placeholder="Title"
                    value={noteUpdateData.title}
                    onChange={handleNoteUpdateChange}
                  />
                  <textarea
                    id="content-update"
                    name="content"
                    placeholder="Note"
                    onChange={handleNoteUpdateChange}
                    value={noteUpdateData.content}></textarea>
                  <div className="notes-tab-note-content-inputs-buttons">
                    <Button className="inputs-cancel-button" onClick={() => setIsEditing(false)}>
                      Cancel
                    </Button>
                    <Button className="inputs-submit-button" onClick={() => updateNote(selectedNote!)}>
                      Submit
                    </Button>
                  </div>
                </div>
              )
            ) : (
              <div className="notes-tab-note-content-content">
                <p>{selectedNote?.content}</p>
              </div>
            )}
          </div>
        </Paper>
      </div>
    </div>
  );
}
