import { PayloadAction, Slice, createSlice } from '@reduxjs/toolkit';
import { SummaryDetail } from '../../interface/summary-detail';

interface summaryState {
  showDetailsEditModal: boolean;
  submissionDetails: SummaryDetail[];
}

const initialState: summaryState = {
  showDetailsEditModal: false,
  submissionDetails: [],
};

const summarySlice: Slice = createSlice({
  name: 'summary',
  initialState: initialState,
  reducers: {
    setShowDetailsEditModal: (state: summaryState, action: PayloadAction<boolean>) => {
      state.showDetailsEditModal = action.payload;
    },
    setSubmissionDetails: (state: summaryState, action: PayloadAction<SummaryDetail[]>) => {
      state.submissionDetails = action.payload;
    },
  },
});

export const { setShowDetailsEditModal, setSubmissionDetails } = summarySlice.actions;

export default summarySlice.reducer;
