import axios from 'axios';
import { WithAbortFn } from '../../@types/external-api';
import { apiWrapper } from '../../config/api-wrapper';
import { ReviewSheetTemplate } from '../../interface/review-sheet-template';
import { LegalEntity } from '../../interface/legal-entity';

class ReviewSheetService {
  public getTemplates(): WithAbortFn<Promise<ReviewSheetTemplate[]>> {
    const source = axios.CancelToken.source();
    return {
      promise: apiWrapper.api.get('/review-sheet/templates').then((res: any) => res.data),
      abort: source.cancel,
    };
  }

  public newReviewSheet(data: FormData): WithAbortFn<Promise<any>> {
    const source = axios.CancelToken.source();
    return {
      promise: apiWrapper.api.post(`/review-sheet/new-review-sheet`, data).then((res: any) => res.data),
      abort: source.cancel,
    };
  }

  public getLegalEntities(): WithAbortFn<Promise<LegalEntity[]>> {
    const source = axios.CancelToken.source();
    return {
      promise: apiWrapper.api.get('/review-sheet/legal-entities').then((res: any) => res.data),
      abort: source.cancel,
    };
  }
}

const reviewSheetService = new ReviewSheetService();
export default reviewSheetService;
