import axios from 'axios';
import { WithAbortFn } from '../../@types/external-api';
import { apiWrapper } from '../../config/api-wrapper';
import { SubmissionHistoryDTO } from '../../interface/submission-history-dto';

class SubmissionHistoryService {
  public getHistory(submissionId: number): WithAbortFn<Promise<SubmissionHistoryDTO[]>> {
    const source = axios.CancelToken.source();

    return {
      promise: apiWrapper.api(`/history/${submissionId}`).then((res: any) => res.data),
      abort: source.cancel,
    };
  }
}

const submissionHistoryService = new SubmissionHistoryService();
export default submissionHistoryService;
