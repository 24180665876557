import { ReactNode, useEffect, useRef, useState } from 'react';

import './index.scss';
import { IOption } from '../multi-select-dropdown';

interface SelectComponentProps {
  options: Array<IOption>;
  selectedOption: IOption;
  onChange: Function;
  name: string;
  isDisabled: boolean;
  label?: string | ReactNode;
  noBorders?: boolean;
  noPadding?: boolean;
  textColor?: string;
}

export default function SelectComponent({
  options,
  selectedOption,
  onChange,
  name,
  isDisabled,
  label,
  noBorders,
  noPadding,
  textColor,
}: SelectComponentProps) {
  const [showDropdown, setShowDropdown] = useState<boolean>(false);
  const myRef = useRef(null);

  useEffect(() => {
    document.addEventListener('click', handleClickOutsideDropDown);

    return () => {
      document.removeEventListener('click', handleClickOutsideDropDown);
    };
  }, []);

  function handleClickOutsideDropDown(event: any) {
    const dropDownArea = myRef.current! as HTMLElement;

    if (dropDownArea && !dropDownArea.contains(event.target)) {
      setShowDropdown(false);
    }
  }

  function toggleDropdown() {
    if (!isDisabled) setShowDropdown(!showDropdown);
  }

  function selectOption(option: IOption) {
    onChange(option);
    setShowDropdown(false);
  }

  return (
    <div className="custom-select-container" ref={myRef}>
      <div className="custom-select" onClick={() => toggleDropdown()}>
        <div className="select-label">{label}</div>

        <button
          className={`select-button ${noBorders ? 'no-borders' : ''} ${noPadding ? 'no-padding' : ''}`}
          style={{ color: textColor }}
          role="combobox"
          aria-label="select button"
          aria-haspopup="listbox"
          aria-expanded="false"
          aria-controls="select-dropdown">
          <span className="selected-option">{selectedOption?.label}</span>
          <span className="arrow"></span>
        </button>
      </div>

      <ul className="select-dropdown" hidden={!showDropdown} role="listbox">
        {options.map((option, index) => (
          <li
            className={`select-dropdown-option ${option.value === selectedOption?.value ? 'selected-option' : ''}`}
            onClick={() => selectOption(option)}
            key={`${option.value}_${index}`}
            value={option.value}>
            <input type="radio" id={option.value} name={name} />
            <label htmlFor="github">{option.label}</label>
          </li>
        ))}
      </ul>
    </div>
  );
}
