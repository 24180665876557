import './index.scss';
import { ProgressBar } from 'react-bootstrap';
import { useEffect, useState } from 'react';
import { useAppSelector, useAppDispatch } from '../../../redux/hooks';
import { MdMarkEmailRead } from 'react-icons/md';
import { Tooltip } from 'react-tooltip';
import { Button } from 'react-bootstrap';
import * as submissionReducer from '../../../redux/reducers/submissionReducer';
import { hasTheReviewBeenSent, getLastReview } from '../../../helpers/reviews-helper';
import { Review } from '../../../interface/review';
import { SubmissionIssueDTO } from '../../../interface/submission-issue-dto';
import { ReviewIssueViolationRule } from '../../../interface/review-issue-violation-rule';
import { formatDate } from '../../../helpers/date-helper';

export default function AnalystDecision() {
  const [issuesViewed, setIssuesViewed] = useState<number>(0);
  const [issuesCount, setIssuesCount] = useState<number>(0);
  const [issuesPercent, setIssuesPercent] = useState<number>(0);

  const { enableReview, submissionReviews, currentRecord } = useAppSelector(state => state.submission);
  const reviews: Review[] = useAppSelector(state => state.submission.reviews);
  const dispatch = useAppDispatch();
  const lastReview = getLastReview(reviews);

  /**
   * Event triggered when the user changes the submission version.
   */
  useEffect(() => {
    let viewedCount = 0;
    let issueCount = 0;

    submissionReviews?.forEach((submissionReview: SubmissionIssueDTO) => {
      submissionReview.reviewIssueViolationRule.forEach((issue: ReviewIssueViolationRule) => {
        if (issue.userReviewStatus !== null) {
          viewedCount++;
        }
        issueCount++;
      });
    });

    setIssuesViewed(viewedCount);
    setIssuesCount(issueCount);
    setIssuesPercent(issueCount === 0 ? 100 : (viewedCount * 100) / issueCount);
  }, [submissionReviews]);

  function handleReviewButton(event: any, enableReviewParam: boolean) {
    event.preventDefault();

    clearInfoAlert();
    dispatch(submissionReducer.setEnableReview(enableReviewParam));
  }

  function clearInfoAlert() {
    dispatch(submissionReducer.setRuleViolationsInfoMessage(''));
  }

  return (
    <div className="analystDecision">
      {(!hasTheReviewBeenSent(lastReview) && currentRecord.mediaType === 'Audio') ||
      currentRecord.mediaType === 'Video' ? (
        <div className="issuesProgress">
          <span>{issuesViewed + '/' + issuesCount} issues viewed</span>
          <ProgressBar now={issuesPercent} label={`${issuesPercent}%`} visuallyHidden />
        </div>
      ) : (
        <></>
      )}
      {hasTheReviewBeenSent(lastReview) ? (
        <div className="analyst-decision-info">
          <div
            data-tooltip-id="analyst-decision-tooltip"
            data-tooltip-content={`The last review was sent on: ${formatDate(lastReview.reviewSheet.createdAt)}`}>
            <MdMarkEmailRead className="analyst-decision-mail" size={30} color="#2cda9b" />
          </div>
          {enableReview ? (
            <Button className="analyst-decision-button cancel-review" onClick={e => handleReviewButton(e, false)}>
              Cancel review
            </Button>
          ) : (
            <div>
              <Button className="analyst-decision-button review-again" onClick={e => handleReviewButton(e, true)}>
                Review again
              </Button>
            </div>
          )}
        </div>
      ) : (
        <></>
      )}
      <Tooltip id="analyst-decision-tooltip" place="top" />
    </div>
  );
}
