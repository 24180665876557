import { PayloadAction, Slice, createSlice } from '@reduxjs/toolkit';
import { NoteDTO } from '../../interface/note-dto';

interface NotesState {
  notes: NoteDTO[];
  selectedNote: NoteDTO | null;
  globalIsDeleting: boolean;
}

const initialState: NotesState = {
  notes: [],
  selectedNote: null,
  globalIsDeleting: false,
};

const notesSlice: Slice = createSlice({
  name: 'submissionHistory',
  initialState: initialState,
  reducers: {
    setNotes: (state: NotesState, action: PayloadAction<NoteDTO[]>) => {
      state.notes = action.payload;
    },
    updateNote: (state: NotesState, action: PayloadAction<NoteDTO>) => {
      let updatedNotes = [...state.notes];

      updatedNotes = updatedNotes.map((note: NoteDTO) => {
        if (note.id === action.payload.id) {
          return action.payload;
        }
        return note;
      });

      state.notes = updatedNotes;
    },
    setSelectedNote: (state: NotesState, action: PayloadAction<NoteDTO>) => {
      state.selectedNote = action.payload;
    },
    setGlobalIsDeleting: (state: NotesState, action: PayloadAction<boolean>) => {
      state.globalIsDeleting = action.payload;
    },
  },
});

export const { setNotes, updateNote, setSelectedNote, setGlobalIsDeleting } = notesSlice.actions;

export default notesSlice.reducer;
